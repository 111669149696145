<template>
  <div>
    <v-row class="justify-center">
      <v-card width="500" class="mt-15">
        <div class="mt-5">
          <v-img
            class=" mx-auto"
            src="uploads/logo.jpg"
            height="100"
            width="100"
          ></v-img>
        </div>
        <h4 class="text-center success--text">Department of Paediatrics</h4>
        <h2 class="text-center mt-2">Login</h2>
        <v-form ref="dform" lazy-validation @submit.prevent>
          <v-card-text>
            <v-text-field
              color="success"
              label="User ID"
              prepend-icon="mdi-account-circle"
              v-model.trim="dform.reg_num"
              required
            />
            <v-text-field
              color="success"
              label="Password"
              :type="showPasswd ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              :append-icon="showPasswd ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPasswd = !showPasswd"
              v-model.trim="dform.passwd"
              required
            />
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-btn
            class="mx-auto"
            color="success"
            :loading="loading"
            @click="processLogin"
            >Login</v-btn
          >
        </v-card-actions>

        <p class="text-center mt-5">
          Copyright &copy; {{ new Date().getFullYear() }}
          <span>Department of Paediatrics,</span><br />
          <span
            >Federal Teaching Hospital, Ido-Ekiti, Ekiti state, Nigeria.</span
          >
        </p>
      </v-card>
      <v-snackbar
        v-model.trim="snackbar"
        :color="snackColor"
        bottom
        :timeout="5000"
      >
        {{ snackMsg }}

        <template v-slot:action="{ attrs }">
          <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </div>
</template>

<script>
// import auth from "@/auth";
import { apiClient } from "@/services";
import { mapActions } from "vuex";
import { snackMixin } from "@/mixins";
export default {
  name: "Login",
  mixins: [snackMixin],
  data() {
    return {
      showPasswd: false,
      dform: this.initializeForm(),
      snackbar: false
    };
  },
  methods: {
    initializeForm() {
      return {
        reg_num: "",
        passwd: ""
      };
    },
    ...mapActions(["setLogin", "setCurUser"]),
    processLogin() {
      if (!this.$refs.dform.validate()) {
        return;
      }
      this.loading = true;
      apiClient
        .post("/login", this.dform)
        .then(res => {
          if (res.data.user_id) {
            this.setCurUser(res.data);
            setTimeout(() => {
              this.setLogin(true);
              this.$router.push({ name: "Dashboard" });
            }, 1000);
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch(err => {
          this.displayMsg(err.message, "error");
        });
    }
  }
};
</script>
